import baguetteBox from 'baguettebox.js';
import { Livewire } from '../../../vendor/livewire/livewire/dist/livewire.esm'

export default () => {

    const initBaguetteBox = () => {
        baguetteBox.run('.gallery')
    }

    initBaguetteBox()

    Livewire.hook('commit', ({ succeed }) => {
        succeed(() => {
            queueMicrotask(() => {
                // https://github.com/livewire/livewire/pull/7897
                queueMicrotask(() => {
                    initBaguetteBox()
                })
            })
        })
    })
}