import * as CookieConsent from "vanilla-cookieconsent";

export default () => {
    if (window.cc) {
        window.cc.reset()
    }

    const revision = 1724923684 // Timestamp

    const cc = CookieConsent

    window.cc = cc

    cc.run({
        mode: 'opt-in',
        revision,
        // guiOptions: {
        //     consentModal: {
        //         layout: 'box wide',
        //         position: 'middle center',
        //     }
        // },

        disablePageInteraction: true,

        categories: {
            necessary: {
                enabled: true,
                readOnly: true,
            },
            marketing: {
                enabled: false,
                readOnly: false,
            },
            analytics: {
                enabled: false,
                readOnly: false,
            },
            external: {
                services: {
                    map: {
                        label: 'Mapbox',
                    },
                    youtube: {
                        label: 'YouTube',
                    },
                },
                enabled: false,
                readOnly: false,
            }
        },
        language: {
            default: 'de',
            autoDetect: 'document',
            translations: {
                de: '/api/translations/c-consent/de.json',
            }
        }
    })
}